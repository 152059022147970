<template>
    <v-data-table
        v-if="items"
        :headers="headers"
        :items="items"
        :items-per-page="5"
        show-select
        v-model="selected"
        item-key="_id"
        class="elevation-1"
    >
        <template v-slot:item.type="{item}">
            {{item.type === 1 ? "一日指定": item.type === 2 ? "曜日指定" : item.type === 3 ? "期間指定" : null}}
        </template>
        <template v-slot:item.date="{item}">
            {{getDateInfo(item)}}
        </template>
        <template v-slot:item.color="{item}">
            <div :style="'width:100px; height:30px; border-radius:10%; background:'+item.color+';'"></div>
        </template>
        <template v-slot:item.actions="{item}">
            <v-btn icon color="error" @click="initDelete([item._id])">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import isoWeek from 'dayjs/plugin/isoWeek'
import dayjs from 'dayjs'
dayjs.extend(isoWeek)
export default {
    props: ["ruleData"],
    data: () => ({
        selected: [],
        items: null,
        headers: [
            {
                text: "ルール区分",
                align: "start",
                value: "type",
            },
            { text: "日付", value: "date" },
            { text: "タイトル", value: "name" },
            { text: "色", value: "color" },
            { text: "操作", sortable:false ,value: "actions" },
        ],
    }),
    mounted() {
        this.items = this.ruleData;
    },
    methods:{
        async initDelete(selectedId){
            let selected
            if(this.selected.length > 0) selected = this.selected
            else selected = selectedId
            if (
                await this.$root.$confirm.open(
                    "削除",
                    "選択した項目を全部削除しますか？元には戻れません。",
                    { color: "error" }
                )
            ){
                await this.$store.dispatch("post", {
                    url: "/api/deleteCalendarRules",
                    showUI: true,
                    rawData: selected,
                });
                this.$emit("updated")
            }
        },
        getDateInfo(rule){
            switch(rule.type){
                case 1:
                    return rule.value.start
                case 2:
                    return rule.value.year+"年 "+this.$gf.convertToDay(dayjs().isoWeekday(rule.value.every).day())
                case 3:
                    return rule.value.start+"から"+rule.value.end
            }
        }
    }
};
</script>

<style>
</style>