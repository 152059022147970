<template>
    <div style="overflow-y:scroll; height:99vh;" class="py-3" v-if="loaded" :key="'calendar' + yearOffSet">
        <div class="d-flex align-center my-3">
            <v-spacer></v-spacer>
            <div class="caption warning--text">重く感じる時は「非表示」にしましょう。</div>
            <v-btn @click="hideCalendar = !hideCalendar">
                <v-icon class="mr-1">mdi-eye</v-icon>
                {{hideCalendar ? "表示" : "非表示"}}
            </v-btn>
        </div>
        <div v-if="!hideCalendar">
            <v-sheet tile class="d-flex justify-space-between">
                <v-btn icon class="ma-2" @click="switchYear(-1)">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="pa-3 text-h5">
                    {{ Number(value.split("-")[0]) + yearOffSet + "年" }}
                </div>
                <v-btn icon class="ma-2" @click="switchYear(1)">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-sheet>
            <div class="calendarCon mb-10">
                <div v-for="(month, index) in 12" :key="index">
                    <div class="text-h5 text-center">{{ index + 1 }}月</div>
                    <v-calendar
                        ref="calendar"
                        v-model="value"
                        type="custom-weekly"
                        :start="dateRange[index].start"
                        :end="dateRange[index].end"
                        :events="events"
                        locale="jpn"
                        :event-overlap-mode="'stack'"
                        :event-overlap-threshold="1"
                        event-more-text="{0}件"
                        @click:more="showEventList"
                        :event-color="getEventColor"
                        style="min-height:370px;"
                    ></v-calendar>
                </div>
            </div>
        </div>
        <template>
            <v-divider class="my-5" />
            <div class="banner pa-2 text-center text-h5 rounded my-3">ルール一覧</div>
            <v-sheet>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="display.newEvent = true">
                        <v-icon>mdi-plus</v-icon>
                        新規
                    </v-btn>
                </div>
                <rule-table
                    :key="'tableKey' + tableKey"
                    v-if="calendarRules"
                    :ruleData="calendarRules"
                    @updated="updateTable"
                />
            </v-sheet>
        </template>
        <!---Dialogs=-->
        <v-dialog v-model="display.newEvent" persistent max-width="500px">
            <v-card class="pa-5">
                <v-form v-model="valid">
                    <v-select
                        label="ルール区分"
                        v-model="ruleForm.type"
                        outlined
                        :items="[
                            { text: '一日指定', value: 1 },
                            { text: '曜日指定', value: 2 },
                            { text: '期間指定', value: 3 },
                        ]"
                        :rules="[(v) => !!v || '必須項目です']"
                    >
                    </v-select>

                    <v-text-field
                        label="行事タイトル"
                        outlined
                        v-model="ruleForm.name"
                        :rules="[(v) => !!v || '必須項目です']"
                    >
                    </v-text-field>

                    <v-menu
                        ref="menuColor"
                        v-model="display.menuColor"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="d-flex pb-3 mb-3"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span class="mr-3">色:</span>
                                <div
                                    :style="
                                        'width:100px; height:30px; border-radius:10%; background:' +
                                        ruleForm.color +
                                        ';'
                                    "
                                ></div>
                            </div>
                        </template>
                        <v-color-picker v-model="colorInput" mode="hexa">
                        </v-color-picker>
                    </v-menu>

                    <div class="d-flex" v-if="ruleForm.type === 2">
                        <v-select
                            label="年"
                            v-model="ruleForm.value.year"
                            outlined
                            :rules="[(v) => !!v || '必須項目です']"
                            :items="fiveYearsfromNow"
                        >
                        </v-select>
                        <v-select
                            label="曜日"
                            v-model="ruleForm.value.every"
                            :rules="[(v) => !!v || v === 0 || '必須項目です']"
                            outlined
                            :items="[
                                { text: '月曜日', value: 1 },
                                { text: '火曜日', value: 2 },
                                { text: '水曜日', value: 3 },
                                { text: '木曜日', value: 4 },
                                { text: '金曜日', value: 5 },
                                { text: '土曜日', value: 6 },
                                { text: '日曜日', value: 0 },
                            ]"
                        >
                        </v-select>
                    </div>
                    <v-menu
                        v-if="ruleForm.type === 1 || ruleForm.type === 3"
                        ref="menu"
                        v-model="display.menu"
                        :close-on-content-click="false"
                        :return-value.sync="ruleForm.value.start"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="ruleForm.value.start"
                                :label="ruleForm.type === 1 ? '日付' : '開始'"
                                prepend-icon="mdi-calendar"
                                readonly
                                :rules="[(v) => !!v || '必須項目です']"
                                outlined
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="ruleForm.value.start"
                            no-title
                            scrollable
                            locale="jp"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="display.menu = false"
                            >
                                キャンセル
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(ruleForm.value.start)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>

                    <v-menu
                        v-if="ruleForm.type === 3"
                        ref="menu2"
                        v-model="display.menu2"
                        :close-on-content-click="false"
                        :return-value.sync="ruleForm.value.end"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="ruleForm.value.end"
                                label="終了"
                                prepend-icon="mdi-calendar"
                                readonly
                                locale="ja"
                                outlined
                                :rules="[(v) => !!v || '必須項目です']"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="ruleForm.value.end"
                            no-title
                            scrollable
                            locale="jp"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="dispay.menu2 = false"
                            >
                                キャンセル
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(ruleForm.value.end)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-form>
                <v-card-actions>
                    <v-btn
                        :disabled="!valid"
                        @click="submitNewRule"
                        color="success"
                        >確定</v-btn
                    >
                    <v-btn @click="display.newEvent = false" color="error"
                        >閉じる</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Overlapped event list -->
        <v-dialog v-model="display.overlapedEvent" max-width="500px">
            <v-sheet class="pa-3">
                <span class="caption error--text">パネル以外の部分に押せば自動的に閉じます</span>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr
                                v-for="(event, index) in overlapedEvent"
                                :key="index"
                            >
                                <td>
                                    <div :style="'width:30px; height:30px; border-radius:10%; background:'+event.color+';'"></div>
                                </td>
                                <td>{{ event.name }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

import RuleTable from "./ruleTable";

export default {
    components: {
        RuleTable,
    },
    data: () => ({
        hideCalendar:false,
        tableKey: 0,
        valid: false,
        ruleForm: {
            type: 1,
            color: "#F48FB1",
            value: {},
        },
        colorInput: "#F48FB1",
        loaded: false,
        yearOffSet: 0,
        display: {
            newEvent: false,
            menu: false,
            menu2: false,
            menuColor: false,
            overlapedEvent:false
        },
        value: "",
        colors: [
            "blue",
            "indigo",
            "deep-purple",
            "cyan",
            "green",
            "orange",
            "grey darken-1",
        ],
        events: [],
        overlapedEvent:[],
        calendarRules: null,
        dateRange: {
            /*
                Consist of property from "1" to "12" as an Object
                which has property of "start" and "end"
            */
        },
    }),
    watch: {
        colorInput() {
            this.ruleForm.color = this.colorInput;
        },
    },
    computed: {
        fiveYearsfromNow() {
            let positives = [],
                negatives = [];
            let thisYear = {
                text: dayjs().format("YYYY年"),
                value: Number(dayjs().format("YYYY")),
            };
            for (let i = 1; i < 5; i++) {
                positives.push({
                    text: dayjs().add(i, "y").format("YYYY年"),
                    value: Number(dayjs().add(i, "y").format("YYYY")),
                });
                negatives.push({
                    text: dayjs().add(-i, "y").format("YYYY年"),
                    value: Number(dayjs().add(-i, "y").format("YYYY")),
                });
            }
            return [...negatives.reverse(), thisYear, ...positives];
        },
    },
    async mounted() {
        this.dateRange = this.generateCalendarRange();
        this.calendarRules = await this.$get.getCalendarRules();
        this.applyCalendarRules(this.calendarRules);
        this.value = dayjs().format("YYYY-MM-DD");
        this.loaded = true;
    },
    methods: {
        async updateTable(){
            this.calendarRules = await this.$get.getCalendarRules(); 
            this.applyCalendarRules(this.calendarRules)
            this.tableKey++;
        },
        getEventColor(event) {
            return event.color;
        },
        switchYear(mode) {
            if (mode === 1) this.yearOffSet++;
            else if (mode === -1) this.yearOffSet--;
            this.dateRange = this.generateCalendarRange(
                dayjs().add(this.yearOffSet, "y").format("YYYY-MM-DD")
            );
        },
        generateCalendarRange(year = null) {
            let result = {};
            for (let i = 0; i < 12; i++) {
                let month = year ? dayjs(year).month(i) : dayjs().month(i);
                let monthStart = month.date(1).format("YYYY-MM-DD");
                let monthEnd = month
                    .add(1, "month")
                    .date(-1)
                    .add(1, "day")
                    .format("YYYY-MM-DD");
                result[i] = {};
                result[i].start = monthStart;
                result[i].end = monthEnd;
            }
            return result;
        },
        async submitNewRule() {
            await this.$store.dispatch("post", {
                url: "/api/submitCalendarRule",
                showUI: true,
                rawData: this.ruleForm,
            });
            this.display.newEvent = false;
            this.updateTable()
        },
        applyCalendarRules(rules) {
            this.events = []
            rules.forEach((rule) => {
                switch (rule.type) {
                    case 1:
                        this.events = this.events.concat(
                            this.$gf.generateType1Events(rule)
                        );
                        break;
                    case 2:
                        this.events = this.events.concat(
                            this.$gf.generateType2Events(rule)
                        );
                        break;
                    case 3:
                        this.events = this.events.concat(
                            this.$gf.generateType3Events(rule)
                        );;
                        break;
                }
            });
            this.tableKey++;
        },
        showEventList(e) {
            this.overlapedEvent = []
            const eventData = dayjs(e.date)
            for(let i = 0 ; i<this.events.length ; i++){
                if(
                    eventData.isBetween(this.events[i].start, this.events[i].end, null , '[]') 
                ){
                    this.overlapedEvent.push(this.events[i])
                }
            }
            this.display.overlapedEvent = true
        },
    },
};
</script>

<style scoped>
.calendarCon {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2em;
}
</style>